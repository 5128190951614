import React, { useEffect, useRef, useState } from "react";
import RoadMap from "../../assets/images/road-map.webp";
import RoadMapEnd from "../../assets/images/road-map-end.webp";
import C1 from "../../assets/images/course/c1.webp";
import C2 from "../../assets/images/course/c2.webp";
import C3 from "../../assets/images/course/c3.webp";
import C4 from "../../assets/images/course/c4.webp";
import C5 from "../../assets/images/course/c5.webp";
import C6 from "../../assets/images/course/c6.webp";
import C7 from "../../assets/images/course/c7.webp";
import C8 from "../../assets/images/course/c8.webp";
import C9 from "../../assets/images/course/c9.webp";
import C10 from "../../assets/images/course/c10.webp";
import C11 from "../../assets/images/course/c11.webp";
import C12 from "../../assets/images/course/c12.webp";
import C13 from "../../assets/images/course/c13.webp";
import C14 from "../../assets/images/course/c14.webp";
import C15 from "../../assets/images/course/c15.webp";
import C16 from "../../assets/images/course/c16.webp";
import C17 from "../../assets/images/course/c17.webp";
import C18 from "../../assets/images/course/c18.webp";
import C19 from "../../assets/images/course/c19.webp";
import C20 from "../../assets/images/course/c20.webp";
import C21 from "../../assets/images/course/c21.webp";
const CourseContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const videoRefs = useRef([]);
  const observerRef = useRef(null);

  // Set up Intersection Observer to detect which video is in view
  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = videoRefs.current.indexOf(entry.target);
            if (index !== -1) {
              setActiveTab(index);
            }
          }
        });
      },
      { threshold: 0.5 }
    ); // Adjust threshold as needed

    videoRefs.current.forEach((video) => {
      if (video) observerRef.current.observe(video);
    });

    return () => {
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (videoRefs.current[index]) {
      const offsetTop =
        videoRefs.current[index].getBoundingClientRect().top +
        window.pageYOffset -
        100; // Adjust -100 to your desired offset
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <div className="course-road-map overflow-hidden">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 d-lg-block d-md-block d-none">
              <div className="position-relative">
                <img className="road-map-img" src={RoadMap} loading="lazy" alt="" />
                <div>
                  <div className="road-map-content">
                    <h5
                      className="road-map-text road-map-text1 shake-animation"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      Interview & Selection
                    </h5>
                    <h5
                      className="road-map-text road-map-text2 shake-animation"
                      data-aos="zoom-in"
                      data-aos-duration="2500"
                    >
                      Orientation
                    </h5>
                    <h5
                      className="road-map-text road-map-text3 shake-animation"
                      data-aos="fade-left"
                      data-aos-duration="2000"
                    >
                      Market Ready Knowledge
                    </h5>
                    <h5
                      className="road-map-text road-map-text4 shake-animation"
                      data-aos="fade-right"
                      data-aos-duration="2500"
                    >
                      Learning & Implementation
                    </h5>
                    <h5
                      className="road-map-text road-map-text5 shake-animation"
                      data-aos="fade-left"
                      data-aos-duration="2000"
                    >
                      Doubt Clearing Sessions
                    </h5>
                    <h5
                      className="road-map-text road-map-text6 shake-animation"
                      data-aos="fade-right"
                      data-aos-duration="2500"
                    >
                      Team Project
                    </h5>
                    <h5
                      className="road-map-text road-map-text7 shake-animation"
                      data-aos="fade-left"
                      data-aos-duration="2000"
                    >
                      Special Sessions
                    </h5>
                    <h5
                      className="road-map-text road-map-text8 shake-animation"
                      data-aos="fade-left"
                      data-aos-duration="2500"
                    >
                      Community & Networking
                    </h5>
                    <h5
                      className="road-map-text road-map-text9 shake-animation"
                      data-aos="fade-right"
                      data-aos-duration="2000"
                    >
                      Hall of Fame
                    </h5>
                  </div>
                  <img
                    data-aos="zoom-in" loading="lazy"
                    data-aos-duration="2000"
                    className="road-map-end-img"
                    src={RoadMapEnd}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-12 d-lg-none d-md-none d-block">
              <img
                className="w-100 h-auto" loading="lazy"
                src={require("../../assets/images/mob-road-map.webp")}
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="course-syllabus">
        <div className="hands-on-section py-5">
          <div className="container">
            <div
              className="section-handson-sticky overflow-hidden"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <h2 className="section-heading">Course Syllabus</h2>
              <h5 className="section-sub-heading mb-5">
                What's inside the Course
              </h5>
            </div>
            <div className="row">
              <div className="col-lg-3 position-relative">
                <div className="hands-on-left-content">
                  <ul className="list-unstyled hands-on-content-mob pl-0">
                    <li>
                      <span className={`hands-on-left-text active `}>
                        Digital Marketing
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="hands-on-right-section">
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 1:</h5>
                        <h5 className="course-sub-heading">
                          Magic is in the Mindset - The Journey Begins Here
                        </h5>
                        <p className="course-card-para">
                          Here, you will learn how to have the right mindset as
                          an Entrepreneur and as a Marketer. You will be able to
                          understand the opportunities in the market and things
                          you need to do to grab those.
                        </p>
                        <p className="course-card-para">
                          This Module covers everything from ‘Why you should
                          learn digital marketing’ till ‘How to succeed using
                          this skill’.{" "}
                        </p>
                        <p className="course-card-para mb-0">
                          Including sections like,
                        </p>
                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">Rich Mindset</li>
                          <li className="course-card-para">Ways to Earn </li>
                          <li className="course-card-para">Belief System</li>
                          <li className="course-card-para">
                            How to Attract Money{" "}
                          </li>
                          <li className="course-card-para">
                            Zone in Business and many more
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C1} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 2:</h5>
                        <h5 className="course-sub-heading">
                          Fundamentals of Marketing
                        </h5>
                        <p className="course-card-para">
                          Here, you will learn how to set everything up to get
                          started.
                        </p>
                        <p className="course-card-para">
                          The most comprehensive module on understanding the
                          Fundamentals of Marketing.
                        </p>
                        <p className="course-card-para">
                          These are more than just modules with straightforward,
                          shallow YouTube-level content.
                        </p>
                        <p className="course-card-para">
                          Each one goes into depth on everything you need to do.
                        </p>
                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">
                            Fundamentals of Marketing
                          </li>
                          <li className="course-card-para">
                            Different types of Marketing
                          </li>
                          <li className="course-card-para">
                            Importance of Digital Marketing
                          </li>
                          <li className="course-card-para">
                            The Internet Marketing Tree
                          </li>
                          <li className="course-card-para">Objectives</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C2} alt="" />
                    </div>
                  </div>

                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 3:</h5>
                        <h5 className="course-sub-heading">
                          Customer Avatar and their Journey
                        </h5>
                        <p className="course-card-para">
                          Here, you will learn how to define a buyer persona on
                          the internet to target them and how to understand
                          customer behaviour in order to target them
                          effectively.
                        </p>

                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">
                            Customer Avatar & Understanding the Customers
                          </li>
                          <li className="course-card-para">
                            Psychology Behind Buying Mindset - Journey
                          </li>
                          <li className="course-card-para">
                            How to Capture Large Market
                          </li>
                          <li className="course-card-para">
                            Map of Customer Journey
                          </li>
                          <li className="course-card-para">
                            Understanding the Market Temperature
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C3} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 4:</h5>
                        <h5 className="course-sub-heading">
                          Becoming Better at Design & Creatives
                        </h5>
                        <p className="course-card-para">
                          This Module will give a deeper understanding on how
                          important Creatives are and how Design skills can make
                          you better in Digital Marketing.
                        </p>

                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">
                            Why Graphic Design is Important
                          </li>
                          <li className="course-card-para">The ‘How & Who’</li>
                          <li className="course-card-para">
                            Branding, Logo & Creative sizes
                          </li>
                          <li className="course-card-para">
                            Creating a Logo - Step by Step
                          </li>
                          <li className="course-card-para">
                            Creating Brochures and Presentations
                          </li>
                          <li className="course-card-para">
                            Designing without a Template (From Scratch)
                          </li>
                          <li className="course-card-para">
                            AI Creatives in Canva
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C4} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 5:</h5>
                        <h5 className="course-sub-heading">
                          Becoming better at Copywriting
                        </h5>
                        <p className="course-card-para">
                          This Module will teach you how to literally print
                          money through the ‘Art of Writing Text’ that makes
                          people take action.
                        </p>

                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">
                            What is Copywriting?
                          </li>
                          <li className="course-card-para">
                            Why Copywriting is important for Marketing
                          </li>
                          <li className="course-card-para">
                            Copywriting Principles
                          </li>
                          <li className="course-card-para">
                            Copywriting Frameworks
                          </li>
                          <li className="course-card-para">
                            Types of Copywriting
                          </li>
                          <li className="course-card-para">
                            How to write better Headlines
                          </li>
                          <li className="course-card-para">
                            Effective Story Framework
                          </li>
                          <li className="course-card-para">
                            How to Make Money using Copywriting Skills
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C5} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 6:</h5>
                        <h5 className="course-sub-heading">Video Editing</h5>
                        <p className="course-card-para">
                          Here, you will learn the Art of Visual Storytelling
                          using basic to advanced Video Editing Frameworks that
                          actually work. This is one of the Most In-Demand
                          skills in the current world.
                        </p>

                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">
                            Introduction to Video Editing
                          </li>
                          <li className="course-card-para">
                            Tools & Techniques
                          </li>
                          <li className="course-card-para">
                            Video Creation Techniques
                          </li>
                          <li className="course-card-para">
                            Basics and 8 Steps in Video Editing
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C6} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 7:</h5>
                        <h5 className="course-sub-heading">
                          Mastering Meta Ads (Level 1)
                        </h5>
                        <p className="course-card-para">
                          Learn ‘Meta’ as a platform and how to effectively
                          advertise using Meta Platforms.This Module includes
                          everything from ‘Starting a Profile’ till ‘Launching
                          your Ad Campaigns’.
                        </p>

                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">
                            Facebook Profile and Pages
                          </li>
                          <li className="course-card-para">Best Practices</li>
                          <li className="course-card-para">
                            Introduction to Meta Ad Manager
                          </li>
                          <li className="course-card-para">
                            Ad account Creation and SOP
                          </li>
                          <li className="course-card-para">
                            Understanding Different Facebook Campaigns
                          </li>
                          <li className="course-card-para">
                            L-1 Lead Generation
                          </li>
                          <li className="course-card-para">
                            Copy, Creative and CTA
                          </li>
                          <li className="course-card-para">
                            Launch your First Ad
                          </li>
                          <li className="course-card-para">
                            Frequent issues in Ads
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C7} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 8:</h5>
                        <h5 className="course-sub-heading">
                          Automation in “GOD” Mode
                        </h5>
                        <p className="course-card-para">
                          Here, you will learn how to make life simpler and
                          efficient using Automations and on-the-go flows to
                          immediately increase your productivity and results.{" "}
                        </p>
                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">
                            What is Automation?
                          </li>
                          <li className="course-card-para">
                            Automation using Privyr
                          </li>
                          <li className="course-card-para">
                            Automation using Zapier
                          </li>
                          <li className="course-card-para">
                            Instagram Comment Automation
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C8} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 9:</h5>
                        <h5 className="course-sub-heading">
                          Sales, Saleees & Saleeeeees
                        </h5>
                        <p className="course-card-para">
                          Here, you will learn the ‘Art of Ethical Selling’,
                          where skill sets such as ‘Effective Selling’ and
                          ‘Persuasive Communication’ will be taught. Learning
                          this one skill along with the Digital Marketing
                          Strategies will help you land high-ticket clients
                          effortlessly. A Complete Module on Sales. Everything
                          from start to end.
                        </p>

                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">What is Selling</li>
                          <li className="course-card-para">
                            Effective Selling + Opportunities
                          </li>
                          <li className="course-card-para">
                            Understanding Business, People, & Leads
                          </li>
                          <li className="course-card-para">
                            Crafting the Sales Presentation
                          </li>
                          <li className="course-card-para">
                            Sales Process - Before, During & After the Call
                          </li>
                          <li className="course-card-para">RASNA Framework</li>
                          <li className="course-card-para">Sales CRM</li>
                          <li className="course-card-para">Do and Don’ts</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C9} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 10:</h5>
                        <h5 className="course-sub-heading">
                          Funnels (Level 1)
                        </h5>
                        <p className="course-card-para">
                          This Module is a game changer in terms of Digital
                          Marketing. This will teach you about Funnels and how
                          to leverage funnels for lead generation. Here you will
                          learn how to build your first lead generation funnel.
                        </p>
                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">What is a Funnel</li>
                          <li className="course-card-para">
                            How to build your Funnel using Page Builders
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C10} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 11:</h5>
                        <h5 className="course-sub-heading">
                          Mastering Meta Ads (Level 2)
                        </h5>
                        <p className="course-card-para">
                          In this Module, you will learn the ‘Art of Conversions
                          Campaigns’. The psychology and the strategy behind
                          making people purchase without even talking to them.
                        </p>
                        <ul className="order-list-course mb-0">
                          <li className="course-card-para">
                            What is a Conversion Campaign
                          </li>
                          <li className="course-card-para">
                            How Retargeting Works
                          </li>
                          <li className="course-card-para">
                            Setting up Pixels to track conversions
                          </li>
                          <li className="course-card-para">
                            Creating a Catalogue
                          </li>
                          <li className="course-card-para">
                            How to run a Conversion Campaigns
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C11} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 12:</h5>
                        <h5 className="course-sub-heading">
                          Funnels (Level 2)
                        </h5>
                        <p className="course-card-para">
                          Learn how to create advanced Funnels. This will teach
                          you the WHAT, WHY & HOW about Funnels and how to
                          leverage the state of the art technology to target
                          people effectively and generate high quality leads.
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            Different types of Funnels & Landing Pages
                          </li>
                          <li className="course-card-para">
                            Copy for Landing Page
                          </li>
                          <li className="course-card-para">
                            Integrating Payment Systems
                          </li>
                          <li className="course-card-para">
                            Making Money as a Funnel Builder
                          </li>
                          <li className="course-card-para">
                            How to build your Own Sales Funnel
                          </li>
                          <li className="course-card-para">
                            How to leverage Sales Funnels for Better Marketing
                            and Customer Experience
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C12} alt="" />
                    </div>
                  </div>

                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 13:</h5>
                        <h5 className="course-sub-heading">AI for Marketing</h5>
                        <p className="course-card-para">
                          This Module is one of the most interesting and
                          application-oriented approaches that will help you use
                          Artificial Intelligence to your advantage during
                          marketing.
                        </p>

                        <ul className="unorder-list-course mb-3">
                          <li className="course-card-para">AI for Marketing</li>
                          <li className="course-card-para">
                            Creating Names, Logos, Text & Creatives using AI
                          </li>
                          <li className="course-card-para">
                            Creating Videos using AI
                          </li>
                          <li className="course-card-para">
                            Product Photography using AI
                          </li>
                          <li className="course-card-para">
                            Directory of AI Tools
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C13} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 14:</h5>
                        <h5 className="course-sub-heading">
                          Google My Business
                        </h5>
                        <p className="course-card-para">
                          This Module will cover the Local SEO part and how to
                          create and optimise Google My Business that will help
                          businesses generate leads organically from Google
                          Search and Maps.
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            What is Local SEO
                          </li>
                          <li className="course-card-para">
                            How to Create Google My Business
                          </li>
                          <li className="course-card-para">
                            How to do Local SEO Effectively
                          </li>
                          <li className="course-card-para">
                            How to Measure the Ranking
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C14} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 15:</h5>
                        <h5 className="course-sub-heading">
                          Mastering Google Ads
                        </h5>
                        <p className="course-card-para">
                          Here, you will learn the effective methods of ‘Search
                          Engine Marketing’ and ‘PPC’. This also helps you
                          understand how to use the world’s biggest search
                          engine for better marketing purposes.
                        </p>
                        <p className="course-card-para font-bold">
                          Video Campaign
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            Understanding the benefits of Video Advertising
                          </li>
                          <li className="course-card-para">
                            Creating and optimising Video Ads for YouTube
                            campaigns
                          </li>
                          <li className="course-card-para">
                            Shopping Campaign
                          </li>
                        </ul>
                        <p className="course-card-para font-bold">
                          Setting up a Google Merchant Center account
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            Creating Product Listings and optimising Product
                            Feeds for Shopping Campaigns
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C15} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 16:</h5>
                        <h5 className="course-sub-heading">
                          Influence through LinkedIn
                        </h5>
                        <p className="course-card-para">
                          Here, you will learn how to use LinkedIn for better
                          marketing and also to build AUTHORITY in any field.
                          This Module is a comprehensive one as this alone can
                          help you connect with the most qualified high-ticket
                          customers.
                        </p>

                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            Why Personal Branding
                          </li>
                          <li className="course-card-para">
                            3 Ways to Build a Personal Brand
                          </li>
                          <li className="course-card-para">Why LinkedIn</li>
                          <li className="course-card-para">
                            LinkedIn Profile Optimization
                          </li>
                          <li className="course-card-para">
                            How to Write Content that works
                          </li>
                          <li className="course-card-para">Sales Navigator</li>
                          <li className="course-card-para">
                            Tools that make life easy
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C16} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 17:</h5>
                        <h5 className="course-sub-heading">Email Marketing</h5>
                        <p className="course-card-para mb-0">
                          Here, you will learn how to use email for better
                          marketing and also to leverage the untapped potential
                          of Email Marketing.{" "}
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            Why Email Marketing
                          </li>
                          <li className="course-card-para">
                            How to build your list
                          </li>
                          <li className="course-card-para">
                            How to write emails that convert
                          </li>
                          <li className="course-card-para">
                            How to send Newsletters
                          </li>
                          <li className="course-card-para">Do and Don’ts</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C17} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 18:</h5>
                        <h5 className="course-sub-heading">
                          Search Engine Optimization (SEO)
                        </h5>
                        <p className="course-card-para mb-0">
                          Here, you will learn the effective methods of ‘Search
                          Engine Optimization’. You will also learn the Art of
                          making any website/ brand/ business on the internet to
                          rank higher in a niche.
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            Basics of SEO & Role of SEO in Digital Marketing
                          </li>
                          <li className="course-card-para">
                            Why Should a Business Invest in SEO
                          </li>
                          <li className="course-card-para">On Page SEO</li>
                          <li className="course-card-para">Technical SEO</li>
                          <li className="course-card-para">Local SEO</li>
                          <li className="course-card-para">Off Page SEO</li>
                          <li className="course-card-para">
                            Competitor Analysis
                          </li>
                          <li className="course-card-para">SEO Audit</li>
                          <li className="course-card-para">
                            Data Analysis in SEO
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C18} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 19:</h5>
                        <h5 className="course-sub-heading">
                          Setting up your Venture
                        </h5>
                        <p className="course-card-para mb-0">
                          Here, you will learn everything about Starting a
                          Company from scratch. This module will help you get
                          clarity on what to do and what not to do when it comes
                          to starting your own venture. Deep knowledge directly
                          from the Experts.
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">Tax Filing</li>
                          <li className="course-card-para">GST </li>
                          <li className="course-card-para">Invoicing</li>
                          <li className="course-card-para">Auditing</li>
                          <li className="course-card-para">
                            Office Spaces etc.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C19} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 20:</h5>
                        <h5 className="course-sub-heading">
                          Influence through Instagram
                        </h5>
                        <p className="course-card-para">
                          Here, you will learn everything about Organic Social
                          and how to create video content on Instagram that gets
                          attention and builds authority for you in any industry
                          that you work in. You will also understand the formula
                          behind a viral video from the Most Consistent and
                          Value providing couple in the Industry.
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            Why you should Create Content
                          </li>
                          <li className="course-card-para">
                            What kind of content should you create
                          </li>
                          <li className="course-card-para">
                            How to structure the Content
                          </li>
                          <li className="course-card-para">
                            How to showcase it for better performance
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C20} alt="" />
                    </div>
                  </div>
                  <div className="row row-margin-bottom">
                    <div className="col-lg-6 col-md-7">
                      <div className="course-card-content">
                        <h5 className="course-sub-heading">Phase 21:</h5>
                        <h5 className="course-sub-heading">Client Retention</h5>
                        <p className="course-card-para ">
                          Here, you will learn about retaining a client after
                          acquiring them. You will also learn the strategies of
                          retaining a client by adding value to the client.
                        </p>
                        <ul className="unorder-list-course mb-0">
                          <li className="course-card-para">
                            How to send a proposal to the client
                          </li>
                          <li className="course-card-para">
                            How to set and create reports
                          </li>
                          <li className="course-card-para">
                            How to automate reports for the client
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5">
                      <img className="course-side-img" loading="lazy" src={C21} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="apply-for-course overflow-hidden py-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2
                  className="section-heading fs-2 mb-4"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  Apply for the Course
                </h2>
                <h5
                  className="section-sub-heading fs-5 mb-5"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  Get the basics right. Enter the industry with confidence.{" "}
                  <br />{" "}
                  <span className="eagle-award-gradient fs-5">
                    Speak with our Expert.
                  </span>{" "}
                </h5>
                <div className="position-relative">
                  <div
                    className="apply-course-card"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <div className="container">
                      <div className="row justify-content-between align-items-center">
                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-4">
                              <div className="mb-lg-0 mb-md-0 mb-2">
                                <p className="apply-course-text  mb-lg-4 mb-md-4 mb-2">
                                  Upcoming Cohort :
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-4 ">
                              <div className="mb-lg-0 mb-md-0 mb-4">
                                <h6 className="apply-course-heading mb-lg-4 mb-md-4 mb-2">
                                  December 2024
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-2">
                              <div className="mb-lg-0 mb-md-0 mb-2">
                                <p className="apply-course-text mb-0">Mode :</p>
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-4 ">
                              <div className="mb-lg-0 mb-md-0 mb-4">
                                <h6 className="apply-course-heading mb-0">
                                  Blended Model (Online + Offline)
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4  col-md-4">
                          <div className="mb-lg-0 mb-md-0 mb-4">
                            <a
                              href="https://optin.socialeagle.in/#/apply"
                              target="_blank"
                            >
                              <button
                                type="button"
                                class="btn btn-book-expert-call mb-2 w-75"
                              >
                                Join Now
                              </button>
                            </a>

                            <p className="apply-course-text mb-0">
                              (Only Limited Students per cohort)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 className="upcoming-batch ">Upcoming Batch</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
